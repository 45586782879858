import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Grid, Margin, PageWrapper } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'


const NotFoundPage = ({data: { 
  sanityPage: {
    title, 
    description, 
    gridItems,
  }
}}: PageProps) => {
  const parsedTitle = 'Franklyn: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },    
  ]
  React.useEffect(() => {
    setTimeout(() => navigate(''), 3000)
  }, [])
  return (
    <StyledPageWrapper>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      <Margin>
        <Grid>
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
        </Grid>
      </Margin>
      <TransitionMask />
    </StyledPageWrapper>
  )
}


const StyledPageWrapper = styled(props => <PageWrapper {...props} />)`
  padding-top: 0;
`


export const query = graphql`
  query NotFoundPageQuery {
    sanityPage(slug: {current: {eq: "404"}}) {
      ...pageFields
    }
  }
`

export default NotFoundPage
